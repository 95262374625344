/* eslint-disable react/no-unescaped-entities */
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Block from "./Block";

const Statistic = () => {
  return (
    <div>
      {/* <!-- Statistic / Promo --> */}
      <section className="relative py-12 lg:py-36 bg-[#f2f2f2] dark:bg-[#212121]">
        <div className="container -mt-20 lg:pb-32">
          <h2 className="mb-12 max-w-5xl font-display text-5xl dark:text-white lg:text-[6rem]">
            Arbetsgivare
          </h2>
          <p className="mb-0 max-w-4xl text-3xl font-medium dark:text-white lg:ml-36">
            Det är enkelt och gratis för dig och din personal att gå med i Krogklubben.
            Utnyttja krogkortet, nyheter och erbjudanden.
          </p>
          {/* <div className="grid grid-cols-2 gap-8 md:grid-cols-4">
            <Block />
          </div> */}
        </div>

        {/* <!-- Promo 1 --> */}
        <div className="container pt-12">
          <div className="items-center justify-between lg:flex">
            <div className="relative text-center lg:w-1/2">
              <Image
                width={585}
                height={585}
                src="/images/nft-game/gradient_glow_large_1.png"
                alt="glow"
                loading="lazy"
                className="absolute scale-150"
              />
              <Image
                width={423}
                height={659}
                src="/images/nft-game/robot_large_1.png"
                alt="robot"
                loading="lazy"
                className="relative -top-10 inline-block"
              />
              <Image
                width={585}
                height={476}
                src="/images/hero/3D_elements.png"
                alt="3d"
                loading="lazy"
                className="absolute top-10 animate-fly"
              />
            </div>
            <div className="lg:w-[45%] lg:pl-16">
              <h2 className="mb-6 font-display text-2xl dark:text-white">
                Om Krogklubben
              </h2>
              <p className="mb-8 text-lg leading-normal text-jacarta-800 dark:text-jacarta-200">
                Ett kort för alla krogpersonal
              </p>
              <p className="mb-12 text-jacarta-800 dark:text-jacarta-200">
                Korgklubben är gratis att gå med för alla krogpersonal. Du hittar
                erbjudanden som du kan utnyttja både lokalt och över hela Sverige.
              </p>
              <Link
                href="https://app.krogklubben.se/register"
                className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white bk-shadow-accent-volume transition-all hover:bg-accent-dark"
              >
                Gå med
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end statistic / promo --> */}
    </div>
  );
};

export default Statistic;
