import Image from "next/image";
import Link from "next/link";

const Hero_8 = () => {
  return (
    <>
      {/* <!-- Hero --> */}
      <section className="hero relative py-20 md:pt-32">
        <Image
          width={613}
          height={415}
          src="/images/patterns/pattern_donut.png"
          alt="pattern donut"
          className="absolute right-0 top-0 -z-10"
        />

        <div className="container">
          <div className="mx-auto max-w-2xl pt-24 text-center">
            <h1 className="mb-6 font-display text-5xl dark:text-white lg:text-6xl">
              Krogklubben
            </h1>
            <p className="mx-auto mb-8 max-w-md text-lg leading-normal dark:text-jacarta-50">
              För alla krogpersonal. Få tillgång till krogkortet & massa erbjudanden. Gratis att gå med*
            </p>
            <Link
              href="https://app.krogklubben.se/register"
              className="inline-block rounded-full bg-accent py-3 px-8 text-center font-semibold text-white bk-shadow-accent-volume transition-all hover:bg-accent-dark"
            >
              Gå med
            </Link>
            <p className="mx-auto mb-0 max-w-md text-xs dark:text-jacarta-400 mt-3">
              *Du måste jobba inom krogbranschen.
            </p>
            <p className="mx-auto mb-8 max-w-md text-xs dark:text-jacarta-400 mt-1">
              <Link href="https://app.krogklubben.se/login" className="dark:hover:text-white hover:text-black">
                Har du redan ett konto? Logga in.
              </Link>
            </p>
          </div>
        </div>
      </section>
      {/* <!-- end hero --> */}
    </>
  );
};

export default Hero_8;
